import React from "react";
import resumeFile from "../documents/resume.pdf";

const generateRandomKey = () => Math.random().toString(36).substr(2, 9);


const Experience = ({ classicHeader, darkTheme }) => {
  const experienceDetails = [
    {
      yearRange: "Oct 2023 - Jun 2024",
      title: "Computer Vision and Full Stack Engineer",
      place: "ISI Plast S.p.A.",
      link: "https://www.isiplast.com/?lang=en",
      desc: "",
      desc_true: false,
      bullet_list: [
        {"Detection and Recognition of IML using OCR": [

          "Trained neural networks for text detection and optical character recognition (OCR).",
          "Developed a tool in C# for data collection using Cognex cameras API.",
          "Applied geometric transformations, color space transformations, and other techniques to generate augmented data in Python.",
          "Retrained Tesseract with specific fonts that is used for IML code.",
          "Evaluated training models with a testing dataset.",
          "Created a Django backend that integrates with the C# API for camera interface, including modules for image processing, prediction, and result storage in a NoSQL database.",
        ]

        //   "Configured a Cassandra cluster for real-time data collection and used SQL Server for persistent data storage. \
        //   Developed backend services with Django and DRF, and created a React frontend for real-time data visualization."
        },
        {"Web Platform": [
          "Configured a Cassandra cluster for real-time data collection and used SQL Server for persistent data storage.",
          "Developed backend services with Django and Django Rest Framework (DRF).",
          "Created a React frontend for real-time data visualization on an internal web platform for manufacturing presses.",

        ]
          // "Configured a Cassandra cluster for real-time data collection and used SQL Server for persistent data storage. \
          // Developed backend services with Django and DRF, and created a React frontend for real-time data visualization."
        },
       ], 
    },
    {
      yearRange: "Jul 2021 - Sep 2023",
      title: "Computer Vision and Full Stack Engineer",
      place: "Seco S.p.A Consultant at TECHNOGYM S.p.A",
      link: "https://seco.com/", 
      desc: "",
      desc_true: false,
      bullet_list: [
        {"Exercise Recognition and Classification Using Gyroscope and Accelerometer Sensors": [

          "Train neural nets to solve problems like human pose estimation, face recognition etc.",
          "Trained temporal layers on frozen ResNet and MobileNet backbones for exercise recognition and classification.",
          "TensorBoard to evaluate CNN models during training and tuned model hyperparameters to better result.",
          "Developed a tool for gathering datasets from a camera and used an open-source tool to tag video for exercise recognition.",
          "Preprocessed image datasets using tools such as OpenCV, scikit-image, Pillow, etc.",
          "Applied geometric transformations, color space transformations, and other techniques to generate augmented data in Python.",
          "Utilized accelerators like Coral and Hailo for efficient pose estimation extraction.",
          "Designed and developed production-ready code in Python and deployed it on embedded boards with Linux or Android operating systems.",

        ]
          // "Developed a C++ application interfacing with Arduino sensors (gyroscope and accelerometer) \
          // to recognize various exercises. Managed the full pipeline from data collection and tagging to model training and deployment on microcontrollers."
        },
        {"Exercise Recognition and classification with Camera":  [

          "Design and develop for unique use cases using deep learning frameworks such as TensorFlow and Tensorflow lite.",
          "Train neural nets to solve problems like human pose estimation using accelerometer and gyroscope data.",
          "Used NumPy and Scikit-learn for augmenting accelerometer and gyroscope data with techniques like noise addition, Interpolation, scaling etc.",
          "Tuning model hyperparameters in TensorFlow during training CNN model",
          "Training and Tagging  is written in Python. For gathered data and deployed the models is written in C and deployed on Arduino.",
          //--JUST FOR", INFORMATION---- Usage of NumPy and Scikit-learn for augmentation techniques such as noise addition, time shifting, scaling, rotation, synthetic data generation, and interpolation to accelerometer and gyroscope datasets
        ]
          // "Developed an exercise recognition and classification application using camera, employing advanced machine learning models \
          // for pose detection. Utilized ResNet and MobileNet with temporal components for motion dynamics and deployed the application on \
          // an NXP board running Android for real-time performance."
        },
       ], 
    },
    {
      yearRange: "Apr 2018 - Jun 2021",
      title: "Computer Vision Engineer",
      place: "Seco S.p.A Consultant at UnipolSai S.p.A",
      link: "https://www.seco.com",
      desc: "",
      desc_true: false,
      bullet_list: [
        {"Automated ID Cards Detection and Classification System from Scanner Pdf or Images": [
          "Developed a system for detecting and classifying ID cards from scanned PDFs or images.", 
          "Used YOLO, ResNet101, and RetinaNet with transfer learning to retrain layers for custom classes.",
          "Collected and tagged datasets using open-source tools like VoTT and LabelImg.",
          "Applied geometric and color space transformations, and other techniques for data augmentation in Python.",
          "Created a Django API for seamless integration into user workflows.",

        ]
          //  "Developed a system to automate detection and classification of ID documents (e.g., identity cards, fiscal cards)\
          //  from scanned images. Collected and tagged a diverse dataset, trained deep learning models using YOLO and\
          //  RetinaNet with TensorFlow, and created a Django API for seamless integration into user workflows."
        },
        {"Automated Classification of Scanned Documents Using CNNs and Triplet Loss": [

          "Train neural nets to solve problem that automated classify 32 types scanned documents. ",
          "Collected a dataset preprocess for unsupervised learning, using CNNs with triplet loss to create embeddings.",
          "Using k-means for clustering all embedings. ",
          "Developed a fast API in python and deployed on AWS integration.",
        ]
          //  "Implemented an automated system for classifying 32 types of consistently scanned documents. \
          //  Collected a dataset for semi-supervised learning, used CNNs for feature extraction, and trained models with triplet loss to create embeddings. \
          //  Mapped document types into an embedding space for k-NN classification. Developed and deployed a Django API for integration on AWS."
        },
        {"Loc2vec Integration for Insurance Client Suggestion Based on Geographic Data": [

          "Train a deep learning model with pytorch using techniques like transfer learning to suggest a new area for new insurance clients based on geographic data.",
          "Gathered data from the OpenStreetMap API, integrated it with internal insurance client data",
          "Developed a pipeline to automate the entire process from training data preparation, model training and evaluation, to deployment.",
        ]
          //  "Integrated Loc2vec to suggest new insurance client areas based on geographic data. Collected data from OpenStreetMap, \
          //  implemented Loc2vec to map locations into vector embeddings, and trained the model to capture spatial relationships. \
          //  Used these embeddings to identify optimal client acquisition areas and validated suggestions with historical data for accuracy."
        }
       ], 
    },
    {
      yearRange: "Oct 2017 - Mar 2018",
      title: "Deep Learning Intership",
      place: "Hopenly acquire by Seco S.p.A",
      link: "https://www.seco.com",
      desc: "",
      desc_true: false,
      bullet_list: [ 
        {"Development of a Network Intrusion Detection System (NIDS)": [
          "Develop a Network Intrusion Detection System using machine learning and deep learning techniques to identify and classify anomalies on the local network.",
          "Preprocessed the CIC-IDS2017 dataset with PySpark on a Hadoop cluster.",
          "Presence of all common available protocols, such as HTTP, HTTPS, FTP, SSH and email protocols.",
          "Feature extracted more than 80 network flow features from the generated network traffic using CICFlowMeter and delivered the network flow dataset.",
          "Employed neural networks, random forests, xgboost and SVMs for anomaly classification. ",

        ]
      },
    ]
    },
    {
      yearRange: "Apr 2014 - Sep 2015",
      title: "Software Engineer Intern",
      place: "Hrtools srl",
      link: "https://hrtools.it/",
      desc: "",
      desc_true: false,
      bullet_list: [
        {"Development of web applications www.hrtools.it": 
          [
          "Designed and developed a web application for HR management software using PHP and MySQL.",
          "Desgin and managed the database schema in MySQL.",
          "Developed backend in PHP and frontend with javascript (jquery, jqwidget)."
          ]
        }
      ]
    },
    
  ];

const DevOpsSkills = [
  {
    name: "Git",
    percent: 99,
  },
  {
    name: "Docker",
    percent: 99,
  },
  {
    name: "Jenkins",
    percent: 99,
  },
  {
    name: "Ansibile",
    percent: 99,
  },
  {
    name: "Kubernetes",
    percent: 99,
  },
  {
    name: "AWS",
    percent: 99,
  },
  {
    name: "GCP",
    percent: 99,
  },
];
const DeepLearningSkills = [ 
  {
    name: "Tensorflow",
    percent: 99,
  },
  {
    name: "Pytorch",
    percent: 99,
  },
  {
    name: "Opencv",
    percent: 99,
  },
  {
    name: "Pillow",
    percent: 99,
  },
  {
    name: "Pandas",
    percent: 99,
  },
  {
    name: "numpy",
    percent: 99,
  },
  {
    name: "Scikit-Learn",
    percent: 99,
  },
];

  const FullStackSkills = [ 
    {
      name: "Python",
      percent: 99,
    },
    {
      name: "JavaScript",
      percent: 99,
    },
    {
      name: "Typescript",
      percent: 99,
    },
    {
      name: "Django (DRF)",
      percent: 99,
    },
    {
      name: "React",
      percent: 99,
    },
    {
      name: "Bootstrap",
      percent: 99,
    }, 
    {
      name: "Postgres",
      percent: 99,
    },
    {
      name: "SQL Server",
      percent: 99,
    },
    {
      name: "MongoDB",
      percent: 99,
    },
    {
      name: "Cassandra",
      percent: 99,
    },
    {
      name: "Redis",
      percent: 99,
    }, 

  ];


  const languageSkills = [ 
    {
      name: "Italian: Native",
      percent: 99,
    },
    {
      name: "English: proficient",
      percent: 99,
    },
    {
      name: "Hindi: Advanced",
      percent: 99,
    },
    {
      name: "Punjabi: Advanced",
      percent: 99,
    },
  ];



  return (
    <section
      id="experience"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Experience
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Experience
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
 
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <a href={value.link} className="text-decoration-none">
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                  </a>    

                  {value.bullet_list && Array.isArray(value.bullet_list) ? (
                    <div>
                      { 
                        // Render keys array if value.desc_true is true
                        value.bullet_list.map((item, idx) => {
                          const [key, value1] = Object.entries(item)[0];

                          return (   
                            <div key={idx}>
                                <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                                  <strong>{key ? `${key}:` : ''}</strong> 
                                
                                </p>   
                                <ul key={idx} className={"mb-0 " + (darkTheme ? "text-white-50" : "")}> 
                                  {Array.isArray(value1) ? (
                                    value1.map((subItem, subIdx) => (
                                      <li key={subItem} style={{ listStyleType: 'disc', marginBottom: '0.1rem' }}>{subItem}</li>
                                    ))
                                  ) : (
                                    <li key={"1"}>{value1}</li>
                                  )
                                  }
                                </ul>
                                </div>
 
                          );
                        }) 
                      } 
                    </div>
                      ) : 
                      (
                        <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                           
                        </p>
                      )
                    } 


                </div>
              ))}
          </div>
        </div>
        {/* Full stack Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Full Stack Skills
        </h2>
        <div className="row gx-5">
          {FullStackSkills.length > 0 &&
            FullStackSkills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div> 
        {/* Deep Learning Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Deep Learning Skills
        </h2>
        <div className="row gx-5">
          {DeepLearningSkills.length > 0 &&
            DeepLearningSkills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div> 

        {/* Devops Skills */}
                <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
         DevOps Skills
        </h2>
        <div className="row gx-5">
          {DevOpsSkills.length > 0 &&
            DevOpsSkills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>  
        {/* Language Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
        Language Skills
        </h2>
        <div className="row gx-5">
          {languageSkills.length > 0 &&
            languageSkills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div> 

        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Experience;
