import React from "react";
import resumeFile from "../documents/resume.pdf";

const Education = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2015 - 2017",
      title: "Master Degree",
      place: "University of Modena and Reggio Emilia, Department of Engineering ”Enzo Ferrari",
      link: "http://www.ingmo.unimore.it/",
      desc: "Modena, Italy",
    }, 
    {
      yearRange: "2011 - 2014",
      title: "Bachelor Degree",
      place: "University of Modena and Reggio Emilia, Department of Engineering ”Enzo Ferrari",
      link: "http://www.ingmo.unimore.it/",
      desc: "Modena, Italy",
    }, 
    {
      yearRange: "2005 - 2010",
      title: "High School Diploma",
      place: "High School State Professional Institute (Filippo Re)",
      link: "https://www.ipsscfilippore.edu.it/",
      desc: "Reggio Emilia, Italy",
    },

  ]; 
     
  return (
    <section
      id="education"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Education
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Education
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-12">
            {/* <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2> */}
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>  
                  <a href={value.link} className="text-decoration-none">
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                  </a>   
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div> 
        </div> 
      </div>
    </section>
  );
};

export default Education;
