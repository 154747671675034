import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Computer Vision Engineer",
      desc: 
      // "Computer Vision Engineer specializing in edge devices, responsible for developing and optimizing computer vision \
      // solutions that can run efficiently on resource-constrained edge computing platforms.\
      // focus on designing and implementing algorithms, models, and systems to solve complex vision\
      // tasks such as object detection, image segmentation, facial recognition, and scene understanding.",

      "Develop applications by overseeing the entire lifecycle, including: ",
    bullet_list: [
      {"Data Collection": "Gathering and preparing datasets tailored for specific vision tasks."},
      {"Data Tagging": "Labeling data to create supervised learning models."},
      {"Model Training and Testing": " Building, training, and evaluating models to ensure accuracy and performance."},
      {"Deployment": "Deploying models using Docker on cloud platforms or integrating them into embedded systems running Linux or Android."},
      {"Monitoring and Performance": "Continuously monitoring the performance of deployed models, including tracking metrics."}
     ],
      icon: "fas fa-microchip",
    },
    {
      name: "Data Engineer & Analysis",
      desc: 
      // "Data Engineer with experience designing, building, and maintaining robust data infrastructure and pipelines. \
      // Skilled in data modeling, ETL development, and cloud technologies. Data Analyst with experience in extracting actionable \
      // insights from complex datasets. Proficient in data cleaning, statistical analysis, and data visualization techniques.",

      "Develop data solutions by managing the entire lifecycle, including:",
      bullet_list: [
        {"Data Infrastructure Design": "Architect and design scalable and efficient data pipelines and systems that support data collection, storage, and processing."},
        {"ETL Development": "Develop and manage Extract, Transform, Load (ETL) processes from various sources into a centralized data warehouse or lake."},
        {"Data Integration": "Integrate data from various sources, ensuring that data from different systems is combined accurately and effectively."},
        {"Deployment": "Design and implement data models that structure data for efficient querying and analysis."},
        {"Data Pipeline Maintenance": "Monitor and maintain data pipelines to ensure they run smoothly and efficiently."}
       ],
      icon: "fas fa-database",
    },
    {
      name: "MLOps",
      desc:"involves a set of practices and tools designed to manage and streamline the lifecycle of machine learning (ML) models from development through to deployment and maintenance. ", 
      // "I am an experienced IT professional specialized in MLOps and DevOps, adept at integrating machine learning workflows\
      //  into software development processes. I excel in utilizing GitHub for version control, orchestrating CI/CD pipelines for automation,\
      //  conducting comprehensive unit testing, and harnessing Docker and Kubernetes for containerization and orchestration.",
      bullet_list: [
        {"Model Training":"Oversee the training of machine learning models, including selecting algorithms, tuning hyperparameters, and optimizing performance."},
        {"Version Control": "Implement version control for models and datasets to track changes, manage multiple versions, and ensure reproducibility."},
        {"Model Deployment": "Deploy machine learning models into production environments, which can include cloud platforms, on-premises servers, or edge devices."},
        {"CI/CD": "Implement CI/CD pipelines for automating the build, testing, and deployment processes of ML models."},
        {"Pipeline Management": "Automate end-to-end ML workflows, including data ingestion, feature engineering, model training, and evaluation."},
        {"Model Monitoring": "Continuously monitor model performance in production, including tracking metrics such as accuracy, precision, and recall."}
       ],
      icon: "fas fa-dev",
    },
    {
      name: "Full Stack Developer",
      desc: 
      "Dvelop both the frontend and backend of web applications.",
      // "As a Full Stack Developer specializing in both backend and frontend technologies, we are responsible for \
      // designing, developing, and maintaining end-to-end solutions. Our responsibilities will include developing RESTful APIs, \
      // implementing database solutions using both  SQL and NoSQL databases, designing user interfaces, and ensuring seamless \
      // integration between the frontend and backend components.",
      bullet_list: [
        {"Frontend Development":"Utilizing frameworks such as React or Angular to build dynamic web applications."},
        {"Backend Development": "Developing server-side logic and APIs, including RESTful endpoints with Django and Nodejs, to facilitate communication between the frontend and backend. "},
        {"Database Solutions": "Designing and integrating databases using both SQL (e.g., PostgreSQL, MySQL) and NoSQL (e.g., MongoDB, Cassandra) "},
        {"CI/CD": "Implementing continuous integration and continuous deployment (CI/CD) pipelines to automate testing, building, and deployment processes using tools like Jenkins or GitHub Actions."},
        {"Cloud Deployment": "Deploying applications on cloud platforms such as AWS, or Google Cloud, ensuring high availability and scalability."} 
       ],

      icon: "fas fa-laptop-code",
    },
    {
      name: "Monitorning",
      desc: "Configure and installs monitoring systems using Grafana, Loki, and Prometheus, ensuring robust performance tracking across diverse environments.",

      bullet_list: [
        {"Grafana": "Deploy Grafana for visualizing metrics and logs, allowing for customizable dashboards and detailed performance analysis."},
        {"Loki": "Install Loki for log aggregation and searching, integrating it with Grafana for a unified view of logs and metrics."},
        {"Prometheus": "Set up Prometheus to collect and store metrics, providing powerful querying capabilities and alerting based on system performance."},
        {"Multi-OS Agents": "Install monitoring agents on various devices with different operating systems to gather and report system data consistently."}
    ],
      icon: "fas fa-computer",
    },
    
    {
      name: "Android Developer",
      desc: "Develop vision applications for embedded board systems using Java and the Android SDK. My work involved creating several apps that utilized  models such as ResNet and MobileNet. This included optimizing these models for performance on embedded systems and deploying them effectively. ",
      icon: "fas fa-android",
    }
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3> 
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p> 
                      {service.bullet_list && Array.isArray(service.bullet_list) ? (
                        <ul className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                          {service.bullet_list.map((item, idx) => {
                            // Extract key-value pairs from the item object
                            const [key, value] = Object.entries(item)[0];
                            return (
                              <li key={idx} style={{ listStyleType: 'disc', marginBottom: '0.5rem' }}>
                                <strong>{key}:</strong> {value}
                              </li>
                            );
                          })}
                        </ul>
                      ) : 
                      (
                        <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                           
                        </p>
                      )
                    } 
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
